<template>
  <div class="basic">
    <div class="left">
      <!-- 热门拼团商品 -->
      <el-form label-width="auto" :inline="false" size="small">
        <el-form-item label="可用会员卡支付">
          <el-switch
            v-model="submitData.UseVipCardPay"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="已完成订单允许申请售后的天数">
          <el-input v-model="submitData.AfterSaleInDays" placeholder="0为不允许售后" size="small">
            <template slot="append">天</template>
          </el-input>
        </el-form-item>
      </el-form>

      <div class="save-btn">
        <el-button type="primary" size="small" @click="saveBasicSetting"
          >保 存</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Shop from "@/api/shop.js";

export default {
  data() {
    return {
      submitData: {
        UseVipCardPay: 0,
        AfterSaleInDays: 0,
      },
    };
  },

  mounted() {
    this.initBasicSetting();
  },

  methods: {
    // 初始化基础设置
    async initBasicSetting() {
      try {
        let { data } = await Shop.initBasicSetting();
        this.submitData = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 保存基础设置
    async saveBasicSetting() {
      let submitData = JSON.parse(JSON.stringify(this.submitData));
      submitData = {
        ...submitData,
        AfterSaleInDays: Number(submitData.AfterSaleInDays)
      }
      try {
        let { errcode } = await Shop.saveBasicSetting(this.submitData);
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.initBasicSetting();
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.left {
  .flex-col;
  align-items: unset;
  max-height: var(--max-height);
  overflow-y: auto;
  box-sizing: border-box;

  .setting-item {
    .flex-col;
    align-items: unset;
    margin-bottom: 20px;
    box-sizing: border-box;

    .title {
      .flex-col;
      justify-content: center;
      align-items: unset;
      font-weight: bold;
      font-size: 16px;
      // border-left: 2px solid @primary-color;
    }
  }

  .el-form{
    .el-input{
      width: 180px;
    }
  }

  .save-btn {
    position: fixed;
    bottom: 10px;
    left: 50vw;
  }
}
</style>
